import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import React from 'react';

export const UsersFindMany = ({ children, filter }) => {
	const { data, loading, error } = useQuery(query('UsersFindMany'),
		{
			variables: {
				sort: '_ID_DESC',
				filter
			},
			fetchPolicy: 'network-only'
		}
	);

	if (loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.UsersFindMany) return <p className={'font-mona f-900 text-white p-3'}>No users found</p>;

	const validUsers = data.UsersFindMany.filter(user => user.firstName && user.lastName);

	if (validUsers.length === 0) {
		return <p className={'font-mona f-900 text-white p-3'}>No valid users found</p>;
	}

	return children(validUsers);
};