import React from 'react';
import PropTypes from 'prop-types';
import {TextArea} from '../../../../components';

export const CheckInQuestionCreateRender = ({formState, onSubmit, handleChange, handleRadioChange,isLoading, isError, allFieldsFilled, close}) => {
	return <div>
		<form className={'w-400px flex flex-col gap-4'}
			  onSubmit={onSubmit}>
			<h1 className={'text-2xl'}>Create your CheckInQuestion:</h1>
			<div className={'flex flex-col gap-2'}>
				<div className={'w-full flex flex-col gap-2'}>
					<div className={'mb-4 mt-5'}>
						<div className={'w-100 flex mb-2 direction-column justify-start align-center self-start'}>
							<label className={'font-sans text-text-light dark:text-text-dark self-start'}>Set
								Start status:</label>
						</div>
						<div className={'flex justify-center align-center gap-5 mb-4'}>
							<label className={'font-sans ml-3 text-text-light dark:text-text-dark'}>Enabled</label>
							<input
								type='radio'
								name='donation'
								value='true'
								checked={formState.start === true}
								onChange={handleRadioChange}
								className={'font-mono p-5'}
							/>
							<label className={'font-sans mr-3 text-text-light dark:text-text-dark'}>Disabled</label>
							<input
								type='radio'
								name='donation'
								value='false'
								checked={formState.start === false}
								onChange={handleRadioChange}
								className={'font-mono p-5'}
							/>
						</div>
					</div>
					<TextArea
						label='Question'
						name='label'
						value={formState.label}
						onChange={handleChange}
					/>
					<div className={'flex justify-around flex-col gap-2 mt-4'}>
						<div className={'flex justify-around gap-2 mt-4'}>
							<button type='button' onClick={close}
								className={'flex gap-3 border items-center w-fit px-2 py-0.5 rounded'}>
								cancel
							</button>
							<button
								disabled={!allFieldsFilled || isLoading}
								type='submit'
								className={'font-mono px-2 py-0.5 text-white bg-button-background-dark rounded hover:bg-opacity-70 disabled:opacity-40'}
							>
								{isLoading ? 'Creating...' : 'Create'}
							</button>
						</div>
						{isError ? <div className={'text-center text-red-600 mt-3'}>{isError.message}</div> : null}
					</div>
				</div>
			</div>
		</form>
	</div>;
};

CheckInQuestionCreateRender.propTypes = {
	formState: PropTypes.shape({
		curriculum: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		start: PropTypes.bool.isRequired,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleRadioChange: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isError: PropTypes.any.isRequired,
	allFieldsFilled: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
};