import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {coursesQueries} from '../actions/Courses.queries';
import {useMutation} from '@apollo/client';
import {coursesMutations} from '../actions/Courses.mutations';
import {uploadCoursesCover} from '../actions/Coures.rest';
import {CoursesCreate} from './CoursesCreate';

export const CoursesCreateLoad = () => {
	const history = useHistory();

	const [formState, setFormState] = useState({
		hidden: false,
		lifeSkill: [],
		skillSet: [],
		theme: [],
		cover: { url: '', type: '' },
		loop: { url: '', type: '' },
		logo: { url: '', type: '' },
		trailer: { url: '', type: '' },
		documents: [],
		language: {
			spanish: { title: '', description: '', identifier: '', label: '' },
			english: { title: '', description: '', identifier: '', label: '' },
			french: { title: '', description: '', identifier: '', label: '' },
		},
		resources: [],
	});

	const [selectedLifeSkills, setSelectedLifeSkills] = useState([]);
	const [selectedSkillSets, setSelectedSkillSets] = useState([]);
	const [selectedThemes, setSelectedThemes] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);

	const [createCourse, { loading: isLoading, error: isError }] =
        useMutation(coursesMutations('curriculumsCreateOne'), {
        	refetchQueries: [{query: coursesQueries('curriculumsFindMany')}],
        	awaitRefetchQueries: true
        });

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const handleCheckboxChange = (e) => {
		const {name, checked} =e.target;
		setFormState((prev) => ({ ...prev, [name]: checked }));
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await createCourse({
				variables: {
					record: {
						...formState,
						totalLessons:formState.totalLessons && parseInt(formState.totalLessons),
						lifeSkill: selectedLifeSkills.map(skill => skill._id),
						skillSet: selectedSkillSets.map(skill => skill._id),
						theme: selectedThemes.map(theme => theme._id)
					},
				},
			});

			const createdCourseId = data.CurriculumsCreateOne?.record?._id;

			if (createdCourseId) {
				if (selectedFile) {
					await uploadCoursesCover(selectedFile, createdCourseId);
				}
				history.push('/courses');
			} else {
				const errorMessage = data.CurriculumsCreateOne?.error?.message || 'Failed to create course';
				console.error('Unexpected error creating course:', errorMessage);
			}
		} catch (error) {
			console.error('Unexpected error creating course:', error.message);
		}
	};

	const allFieldsFilled = !!formState.title && !!formState.description && !!formState.teacher && !!formState.slug;

	return (
		<CoursesCreate
			isLoading={isLoading}
			isError={isError}
			formState={formState}
			selectedThemes={selectedThemes}
			selectedSkillSets={selectedSkillSets}
			selectedLifeSkills={selectedLifeSkills}
			setSelectedSkillSets={setSelectedSkillSets}
			setSelectedLifeSkills={setSelectedLifeSkills}
			setSelectedThemes={setSelectedThemes}
			allFieldsFilled={allFieldsFilled}
			handleFileChange={handleFileChange}   
			handleChange={handleChange}
			handleCheckboxChange={handleCheckboxChange}
			handleSubmit={handleSubmit}
		/>
	);
};