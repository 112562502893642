import React, {useState} from 'react';

export const CheckBox = ({value,label, onChange, defaultChecked,...props})=>{
	const [checked, setChecked] = useState(!!defaultChecked);
	const handleOnchange = (e)=> {
		// e.target.value=e.target.checked;
		setChecked(e.target.checked);
		onChange(e);
	};

	return (
		<div className={'flex gap-2'}>
			<label className={'font-sans ml-3 text-text-light dark:text-text-dark'}>
				{label}
			</label>
			<input
				{...props}
				checked={checked}
				onChange={handleOnchange}
				type={'checkbox'}
			/>
		</div>
	);
};