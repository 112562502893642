import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {CoursesEditRender} from './CoursesEditRender';
import {Loader} from '../../components';
import {coursesQueries} from '../Courses/actions/Courses.queries';

export const CoursesEditLoad = () => {
	const history = useHistory();
	const { id, tab } = useParams();
	const { loading, error, data, refetch} = useQuery(coursesQueries('CurriculumsFindOne'), {
		variables: { id },
		nextFetchPolicy: true
	});
	const curriculum = data?.CurriculumsFindOne;

	if(loading)
		return <div className={'font-mona f-600 text-white p-3'}>
			<Loader/>
		</div>;

	return (
		<CoursesEditRender history={history} id={id} curriculum={curriculum} refetch={refetch}/>
	);
};