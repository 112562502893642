import PropTypes from 'prop-types';
import {Response} from '../Response';
import {ResponseForm} from '../ResponseForm';
import {useState} from 'react';
import {EditDeleteActions} from '../../../../components/layout';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {MdDragIndicator} from 'react-icons/md';

export const QuestionRender =({responses,question, points, questionIndex,onEdit,refetchResponses, onDelete}) =>{
	const [addResponse,setAddResponse] = useState(false);
	const handleOnDragEnd = (result) => {
		const destination = result.destination; // {droppable?Id : string, index : number}
		const source = result.source; // {droppable?Id : string, index : number}
		console.log({destination,source});
		// onResortQuestions(source.index,destination.index);
	};
	return (
		
		<div className='bg-midnight-light flex flex-col gap-1 px-4 py-2 rounded-md'>
			<div className={'flex justify-between mt-2'}>
				<h4 className={'flex font-bold text-2xl'}>
					{questionIndex}.
					<span className={'pl-3'}
						dangerouslySetInnerHTML={{__html: question.label}}
					/>
				</h4>
				<div className={'flex gap-4'}>
					<button
						className={'flex gap-3 border items-center w-fit px-2 py-0.5 rounded'}
						type={'button'}
						onClick={() => setAddResponse(true)}
					>
						<div
							className={'font-mono w-[20px] h-[20px] flex justify-center items-center cursor-pointer font-medium text-white bg-background-dark dark:bg-brightLilac-default rounded-3xl'}>
							+
						</div>
						<p>Add Answer</p>
					</button>
					<EditDeleteActions onDelete={onDelete} onEdit={onEdit}/>
				</div>

			</div>

			<h2 className={'font-bold'}>Points: {points}</h2>

		
			<Droppable droppableId={question._id} type={`droppableSubItem`}>
				{(provided) => (
					<div {...provided.droppableProps} className={'responses-list mt-3 mb-3 '} ref={provided.innerRef}>
						{question.responses.length ? (
							responses.map((response,index) => (
								<Draggable key={response._id} draggableId={response._id} index={index}>
									{(provided, snapshot)=>(
										<div
											key={response._id}
											ref={provided.innerRef}
											{...provided.draggableProps}
											className={`flex items-center gap-1 p-2 mb-2 bg-gray-800 rounded ${snapshot.isDragging && 'bg-gray-600'} `}
										>
											<div className={'h-full'} {...provided.dragHandleProps}>
												<MdDragIndicator />
											</div>
											<div className={`${snapshot.isDraggingOver && 'opacity-50'}`}>
												<Response
													response={response}
													refetchResponses={refetchResponses}
													question={question}

												/>
											</div>
											{provided.placeholder}
										</div>
									)}
								</Draggable>
							))
						) : (
							<p>There is no answers yet</p>
						)}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		
			

			<ResponseForm
				question={question}
				isOpen={addResponse}
				setIsOpen={setAddResponse}
			/>
		</div>
			
	);
};

QuestionRender.propTypes = {
	question: PropTypes.shape({
		question: PropTypes.string.isRequired,
		points: PropTypes.string.isRequired,
	}).isRequired,
	questionIndex:PropTypes.number.isRequired,
	points:PropTypes.arrayOf(PropTypes.number),
	onEdit:PropTypes.func.isRequired,
	onDelete:PropTypes.func.isRequired,
};