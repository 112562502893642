import {useQuery} from '@apollo/client';
import React from 'react';
import {coursesQueries} from '../pages/Courses/actions/Courses.queries';


export const CurriculumFindOne = ({ id } ) => {
	const { data, loading, error } = useQuery(coursesQueries('CurriculumsFindOne'),{
		variables: { id },
		fetchPolicy: 'network-only'
	});

	if (loading) return <p className={'font-mona f-600 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-600 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.CurriculumsFindOne) return <p className={'font-mona f-500 text-white'}>Curriculum not found</p>;

	const curriculum = data.CurriculumsFindOne;

	return <span>{curriculum.title}</span>;
};