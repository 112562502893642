import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { uploadCurriculumDocument } from '../../../../actions/restactions';
import Button from '../../../../components/ui/Button';
import { DocumentForm } from '../../../LessonsEdit/components';
import { CoursesTableMainWrapper } from '../../../Courses/Courses.style';
import PropTypes from 'prop-types';
import {coursesMutations} from '../../../Courses/actions/Courses.mutations';

export const DocsTab = ({id, documents, refecth}) => {
	const [showDocumentForm,setShowDocumentForm] = useState(false);
	const [selectedDocument,setSelectedDocument] = useState(false);

	const handleEditDocument = (document) => {
		setSelectedDocument(document);
		setShowDocumentForm(true);
	};

	const [ updateCoursesDocument, { loading: isUpdating } ] = useMutation(coursesMutations('curriculumsUpdateOne'), {
		refetchQueries: [ 'CurriculumsFindOne' ],
		awaitRefetchQueries: true,
	});

	return (
		<div className={'w-full text-white px-4'}>
			<div className={'flex justify-between gap-4 items-center mb-4'}>
				<h2 className={'h2'}>Courses Documents</h2>
				<Button type={'button'} className={'bg-brightLilac-default'}
					onClick={()=>{
						setSelectedDocument(null);
						setShowDocumentForm(true);
					}}>
					New Document +
				</Button>
			</div>

			<div className={'w-full br border-text-dark border overflow-hidden px-2 pb-4'}>
				<CoursesTableMainWrapper
					className={'w-100 mt rounded-lg p2.5 '}
					style={{border: '1px solid transparent', boxDecorationBreak: 'unset'}}
				>
					<thead>
						<tr className={'t-white'}>
							<th className={'left main-color-background p-2'}>Title</th>
							<th className={'left font-mono main-color-background p-2'}>Link</th>
							<th className={'left font-mono main-color-background p-2'}>type</th>
						</tr>
					</thead>
					<tbody className={'font-sans text-xs text-text-dark '}>
						{documents.map((d, id)=>
							<tr className={'cursor-pointer hover:bg-[rgb(18,29,61)] my-2 p-2'}
								key={d.url+id}
								onClick={() => handleEditDocument(d)}
							>
								<td className={'p-2 py-1 text-left'}> {d.title}</td>
								<td className={'p-2 truncate max-w-xs overflow-ellipsis text-left'}>
									url: <a href={d.url} target={'_blank'}>{d.url}</a>
								</td>
								<td className={'p-2 truncate text-left'}>{d.type}</td>
							</tr>
						)}

					</tbody>
				</CoursesTableMainWrapper>
			</div>


			{!documents.length?
				<div className={'pt-10 w-100'}>
					<p className={'h4 center'}>This Course do not have documents yet.</p>
				</div>
				: null
			}

			<DocumentForm
				id={id}
				isOpen={showDocumentForm}
				setIsOpen={setShowDocumentForm}
				uploadDocument={uploadCurriculumDocument}
				selectedDocument={selectedDocument}
				updateDocument={updateCoursesDocument}
				documentsArray={documents ? documents : []}
				refecth={refecth}
				isCourse={true}
			/>
		</div>
	);
};

DocsTab.prototype ={
	id:PropTypes.string.isRequired,
	documents:PropTypes.arrayOf(PropTypes.shape({
		title:PropTypes.string.isRequired,
		url:PropTypes.string.isRequired,
		type:PropTypes.string.isRequired,
	})),
	refetch: PropTypes.func.isRequired,
};