import gql from 'graphql-tag';

export const mutations={
	questionsCreateOne:gql`
	mutation QuestionsCreateOne($record: CreateOnequestionsInput! ) {
	  QuestionsCreateOne(record: $record) {
		recordId
	  }
	}`,
	questionsUpdateOne:gql`
	mutation QuestionsUpdateOne($id:String!,$record:UpdateByIdquestionsInput! ){
	  QuestionsUpdateOne(_id: $id, record: $record) {
          recordId
          record {
              _id
              label
              labelHtml
              identifier
              responses
              rightAnswers
              createdAt
              updatedAt
          }
          error {
              message
          }
	  }
	}`,
	responsesCreateOne:gql`
	mutation ResponsesCreateOne($record:CreateOneresponsesInput! ){
		ResponsesCreateOne(record: $record) {
			recordId
		}
	}`,
	responsesUpdateOne:gql`
	mutation ResponsesUpdateOne($id:String!,$record:UpdateByIdresponsesInput! ) {
		ResponsesUpdateOne(_id: $id, record: $record) {
			recordId
		}
	}`,
	classesCreateOne:gql`
	mutation ClassesCreateOne($record:CreateOneclassesInput! ){
	  ClassesCreateOne(record: $record) {
		recordId
	  }
	}`,
	classesUpdateOne:gql`
	mutation ClassesUpdateOne($id:String!,$record:UpdateByIdclassesInput! ){
	  ClassesUpdateOne(_id: $id, record: $record) {
		recordId
	  }
	}`,
	/*Life Skills*/
	lifeSkillsCreateOne:gql`
        mutation LifeSkillCreateOne($record: CreateOnelifeskillInput!) {
            LifeSkillCreateOne(record: $record) {
                recordId
                record {
                    _id
                    label
                    identifier
                    icon {
                        url
                        type
                    }
                    description
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
	`,
	lifeSkillCreateOne:gql`
        mutation LifeSkillCreateOne($record: CreateOnelifeskillInput!) {
            LifeSkillCreateOne(record: $record) {
                recordId
                record {
                    _id
                    label
                    identifier
                    icon {
                        url
                        type
                    }
                    description
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
	`,
	lifeSkillUpdateOne: gql`
        mutation LifeSkillUpdateOne($id: String!, $record: CreateOnelifeskillInput!) {
            LifeSkillUpdateOne(_id: $id, record: $record) {
                recordId
                record {
                    _id
                    label
                    identifier
                    icon {
                        url
                        type
                    }
                    description
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
	`,
	lifeSkillDeleteOne:gql`
        mutation LifeSkillDeleteOne($id:String!) {
            LifeSkillDeleteOne(lifeSkill: $id)
        }
	`,
	//Theme
	themeCreateOne: gql`
		mutation ThemeCreateOne($record: CreateOnethemeInput!){
			ThemeCreateOne(record: $record){
                recordId
                record {
                    _id
                    label
                    identifier
                    icon{
                        type
                        url
                    }
                    description
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
			}
		}
	`,
	themeUpdateOne: gql`
        mutation ThemeUpdateOne($id: String!, $record: UpdateByIdthemeInput!) {
            ThemeUpdateOne(_id: $id, record: $record) {
                recordId
                record {
                    _id
                    label
                    identifier
                    icon {
                        type
                        url
                    }
                    description
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
	`,
	// SkillSet mutations
	skillSetCreateOne: gql`
		mutation SkillSetCreateOne($record: CreateOneskillsetInput!){
			SkillSetCreateOne(record: $record){
                recordId
                record {
                    _id
                    label
                    identifier
                    icon{
                        url
                        type
                    }
                    description
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
		}
	`,
	skillSetUpdateOne: gql`
		mutation SkillSetUpdateOne($id: String!, $record: UpdateByIdskillsetInput!){
            SkillSetUpdateOne(_id: $id, record: $record){
                recordId
                record {
                    _id
                    label
                    identifier
                    icon{
                        type
                        url
                    }
                    description
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
			}
		}
	`,
	//Curriculums Mutation
	curriculumsCreateOne: gql`
        mutation CurriculumsCreateOne($record: CreateOnecurriculumsInput!) {
            CurriculumsCreateOne(record: $record) {
                recordId
                record {
                    _id
                    title
                    description
                    hidden
                    slug
                    teacher
                    animalCategory
                    documents {
                        title
                        url
                        type
                    }
                    language {
                        spanish {
                            title
                            description
                            identifier
                            label
                        }
                        english {
                            title
                            description
                            identifier
                            label
                        }
                        french {
                            title
                            description
                            identifier
                            label
                        }
                        _id
                    }
                    leaderBio
                    leaderName
                    active
                    cover {
                        url
                        type
                    }
                    loop {
                        url
                        type
                    }
                    logo {
                        url
                        type
                    }
                    trailer {
                        url
                        type
                    }
                    learningGoal
                    lifeSkill
                    skillSet
                    theme
                    donation
                    resources {
                        _id
                        url
                        type
                        title
                    }
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
	`,

	//Mood Mutations
	MoodCreateOne: gql`
        mutation MoodCreateOne($record: CreateOnemoodInput!) {
            MoodCreateOne(record: $record) {
                recordId
                record {
                    _id
                    label
                    identifier
                    cover{
                        url
                        type
                    }
                    video{
                        url
                        type
                    }
                    description
                    order
                    tips{
                        title
                        description
                        link
                    }
                    color
                    textColor
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
	`,
	moodUpdateOne: gql`
		mutation MoodUpdateOne($_id: String!, $record: UpdateByIdmoodInput!){
            MoodUpdateOne(_id: $_id, record: $record){
                recordId
                record {
                    _id
                    label
                    identifier
                    cover{
                        url
                        type
                    }
                    video{
                        url
                        type
                    }
                    description
                    order
                    tips{
                        title
                        description
                        link
                    }
                    color
                    textColor
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
		}
	`,
	MoodDeleteOne: gql`
        mutation MoodDeleteOne($mood: String!) {
            MoodDeleteOne(mood: $mood)
        }
	`,
	//Avatar Mutations
	AvatarCreateOne: gql`
		mutation AvatarCreateOne($identifier: String!, $label: String!, $price: Int!, $type: String!, $curriculum: String){
        	AvatarCreateOne(identifier: $identifier, label: $label, price: $price, type: $type, curriculum: $curriculum){
                _id
                label
                identifier
                type
                artwork {
                    url
                    type
                }
                price
                curriculum
                createdAt
                updatedAt
			}
		}
	`,
	AvatarUpdateOne: gql`
        mutation AvatarUpdateOne($_id: String!,  $curriculum: String, $identifier: String!, $label: String!, $price: Int!, $type: String!){
            AvatarUpdateOne(_id: $_id, curriculum: $curriculum, identifier: $identifier, label: $label, price: $price, type: $type)
		}
	`,
	AvatarDeleteOne: gql`
        mutation AvatarDeleteOne($_id: String!) {
            AvatarDeleteOne(_id: $_id)
        }
    `,
	//Collectibles Mutations
	CollectiblesCreateOne: gql`
        mutation CollectiblesCreateOne($title: String!, $description: String!, $price: Int!, $curriculum: String!, $editions: Int!) {
            CollectiblesCreateOne(title: $title, description: $description, price: $price, curriculum: $curriculum, editions: $editions) {
                _id
                title
                cover {
                    url
                    type
                }
                artwork {
                    url
                    type
                }
                description
                curriculum
                price
                editions
                deletedAt
                createdAt
                updatedAt
        }
    }`,
	CollectiblesDeleteOne: gql`
        mutation CollectiblesDeleteOne($_id: String!) {
            CollectiblesDeleteOne(_id: $_id)
    }`,
	CollectiblesUpdateOne: gql`
        mutation CollectiblesUpdateOne($_id: String!, $title: String!, $description: String!, $price: Int!, $curriculum: String, $editions: Int!) {
            CollectiblesUpdateOne(_id: $_id, title: $title, description: $description, price: $price, curriculum: $curriculum, editions: $editions)
    }`,
	//Pin Mutations:
	PinCreateOne: gql`
        mutation PinCreateOne($record: CreateOnepinInput!) {
            PinCreateOne(record: $record) {
                recordId
                record {
                    _id
                    label
                    identifier
                    class
                    sparkLibrary
                    deletedAt
                    cover {
                        url
                        type
                    }
                    video {
                        url
                        type
                    }
                    times
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	PinUpdateOne: gql`
		mutation PinUpdateOne($record: UpdateManypinInput!, $filter: FilterUpdateManypinInput, $sort: SortUpdateManypinInput, $skip: Int, $limit: Int){
			PinUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip, limit: $limit){
                numAffected
                error {
                    message
                }
			}
		}
	`,
	DistrictCreateOne: gql`
		mutation DistrictCreateOne($record: CreateOnedistrictInput!) {
			DistrictCreateOne(record: $record) {
			recordId
				record {
				  _id
				  name
				  state
				  country
				  createdAt
				  updatedAt
				}
				error {
				  message
				}
			}	
		}
	`,
	DistrictUpdateOne: gql`
        mutation DistrictUpdateOne($_id: String!, $record: UpdateByIddistrictInput!) {
            DistrictUpdateOne(_id: $_id, record: $record) {
                recordId
                record {
                    _id
                    name
                    state
                    country
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	StickerCreateOne: gql`
        mutation StickerCreateOne($record: CreateOnestickerInput!){
            StickerCreateOne(record: $record){
                recordId
                record {
                    _id
                    label
                    identifier
                    deedlyVault
                    deletedAt
                    cover{
                        url
                        type
                    }
                    video{
                        url
                        type
                    }
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	StickerUpdateOne: gql`
        mutation StickerUpdateOne($_id: String!, $record: UpdateByIdstickerInput!){
            StickerUpdateOne(_id: $_id, record: $record){
                recordId
                record {
                    _id
                    label
                    identifier
                    deedlyVault
                    deletedAt
                    cover{
                        url
                        type
                    }
                    video{
                        url
                        type
                    }
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	BadgesCreateOne: gql`
        mutation BadgesCreateOne($label: String!, $identifier: String!, $curriculum: String!) {
            BadgesCreateOne(label: $label, identifier: $identifier, curriculum: $curriculum) {
                _id
                label
                identifier
                curriculum
                cover {
                    url
                    type
                }
                video {
                    url
                    type
                }
                deletedAt
                createdAt
                updatedAt
            }
        }
    `,
	BadgesUpdateOne: gql`
        mutation BadgesUpdateOne($_id: String!, $record: UpdateByIdbadgeInput!) {
            BadgesUpdateOne(_id: $_id, record: $record) {
                recordId
                record {
                    _id
                    label
                    identifier
                    curriculum
                    cover {
                        url
                        type
                    }
                    video {
                        url
                        type
                    }
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	SchoolCreateOne: gql`
        mutation SchoolUpdateOne($record: CreateOneschoolsDataInput!) {
            SchoolUpdateOne(record: $record) {
                recordId
				record {
				  _id
				  name
				  state
				  city
				  district
				  country
				  slug
				  deletedAt
				  createdAt
				  updatedAt
				}
				error {
				  message
				}
 			}
        }
    `,
	SchoolUpdateOne: gql`
        mutation SchoolUpdateOne($_id: String!, $record: UpdateByIdschoolsDataInput!) {
            SchoolUpdateOne(_id: $_id, record: $record) {
                recordId
				record {
				  _id
				  name
				  state
				  city
				  district
				  country
				  slug
				  deletedAt
				  createdAt
				  updatedAt
				}
				error {
				  message
				}
 			}
        }
    `,
	userCreateOne: gql`
        mutation CreateUser(
            $firstName: String!,
            $lastName: String!,
            $email: String!,
            $organization: String!,
            $type: String!,
            $password: String!
        ) {
            CreateUser(
                firstName: $firstName,
                lastName: $lastName,
                email: $email,
                organization: $organization,
                type: $type,
                password: $password
            )
        }
    `,
	userUpdateOne: gql`
        mutation UserUpdateOne(
            $_id: String!,
            $record: UpdateByIdusersInput!
        ){
            UserUpdateOne(_id: $_id, record: $record) {
                recordId
                record {
                    _id
                    id
                    firstName
                    lastName
                    userName
                    tag
                    bio
                    birthday
                    email
                    profilePicture{
                        url
                        type
                    }
                    points
                    type
                    lastLogin
                    organization
                    cleverId
                    classLinkId
                    cleverSync
                    classLinkSync
                    deedlyAuthenticationToken
                    deedlyUserId
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	SetUserSchool: gql`
        mutation SetUserSchool($user: String!, $school: String!){
            SetUserSchool(user: $user, school: $school)
        }
    `,
};

export const mutation=(value)=>{
	return mutations[value];
};



