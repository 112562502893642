import React from 'react';
import { UsersFindMany, ThemesFindMany, LifeSkillFindMany, SkillSetFindMany} from '../../../hoc';
import {CheckBox, ImageInput, Radio, TagsSelector, TextArea} from '../../../components';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import Input from '../../../components/ui/Input';
import Select from '../../../components/ui/Select';
import {useHistory} from 'react-router-dom';

export const CoursesCreate = ({
	isLoading,isError,
	formState,
	selectedThemes,
	selectedSkillSets,
	handleFileChange,
	selectedLifeSkills,
	setSelectedSkillSets,
	setSelectedLifeSkills,
	 setSelectedThemes,
	allFieldsFilled,
	handleChange,
	handleCheckboxChange,
	handleSubmit,
}) => {
	const history = useHistory();
	return (
		<div className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Create Course</h1>
			<div className={'flex gap-2 justify-center align-center rounded-lg'}
				 style={{backgroundColor: 'rgba(11, 23, 57, 1)', border: '1px solid rgba(52, 59, 79, 1)'}}>
				<form className={'p-5 w-100 '}
					  onSubmit={handleSubmit}>
					
					<section className={'grid grid-cols-2 gap-4 '}>
						<ImageInput
							label={' Add file'}
							className={'rounded-md overflow-hidden'}
							onChange={handleFileChange}
						/>

						<div className={'flex-grow '}>

							<Input
								type='text'
								label={'Title'}
								name='title'
								value={formState.title}
								onChange={handleChange}
							/>

							<TextArea
								label={'Description'}
								name='description'
								value={formState.description}
								onChange={handleChange}
							/>
							<UsersFindMany
								filter={{type:'a4f84644-9b6f-11ee-ab20-325096b39f47'}} // type : tutor
							>
								{users => (
									<Select
										label={'Teacher'}
										name={'teacher'}
										defaultValue={formState.teacher}
										options = {users.map(user=>({
											label:user.firstName + ' '+ user.lastName,
											value:user._id
										}))}
										onChange={handleChange}
									/>
								)}
							</UsersFindMany>
						</div>
					</section>

					<div className={'w-100 '}>
						<CheckBox
							name={'mini'}
							label={'Mini course'}
							onChange={handleCheckboxChange}
						/>
						<CheckBox
							name={'active'}
							label={'Curriculum enabled'}
							onChange={handleCheckboxChange}
						/>

						<CheckBox
							name={'donation'}
							label={'Curriculum Donations enabled'}
							onChange={handleCheckboxChange}
						/>


					</div>
					<div className={'w-full grid grid-cols-2 gap-4'}>
						<Input
							label={'Slug'}
							type='text'
							name='slug'
							value={formState.slug}
							onChange={handleChange}
						/>
						<Input
							type='text'
							label={'Course Duration'}
							name='courseDuration'
							value={formState.courseDuration}
							onChange={handleChange}
						/>
						<Input
							type='text'
							label={'Lesson Length'}
							name='lessonLength'
							value={formState.lessonLength}
							onChange={handleChange}
						/>
						<Input
							type='number'
							label={'Total Lessons'}
							name='totalLessons'
							value={formState.totalLessons}
							onChange={handleChange}
						/>
					</div>


					<TextArea
						label={'Leader Bio'}
						name='leaderBio'
						value={formState.leaderBio}
						onChange={handleChange}
					/>

					<TextArea
						label={'Goal'}
						name='learningGoal'
						value={formState.learningGoal}
						onChange={handleChange}
					/>

					<TagsSelector
						selectedTags={selectedLifeSkills}
						setSelectedTags={setSelectedLifeSkills}
						HOC={LifeSkillFindMany}
						label='Life Skills'
					/>
					<TagsSelector
						selectedTags={selectedSkillSets}
						setSelectedTags={setSelectedSkillSets}
						HOC={SkillSetFindMany}
						label='Skill Sets'
					/>
					<TagsSelector
						selectedTags={selectedThemes}
						setSelectedTags={setSelectedThemes}
						HOC={ThemesFindMany}
						label='Themes'
					/>
					<div className={'flex center justify-center align-center mt-6'}>
						<button
							type='submit'
							disabled={!allFieldsFilled || isLoading}
							className={`flex mb-3 self-center align-center justify-center button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default ${
								!allFieldsFilled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							}`}
						>
							{isLoading ? 'Creating Course...' : 'Create Course'}
						</button>
					</div>
					{isError &&
							<p className={'text-red-500'}>{isError.message ? isError.message : 'An error occurred'}</p>}
				
				</form>
			</div>
		</div>
	);
};